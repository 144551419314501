import { NewsletterSubscriprionComponent } from './shared/components/newsletter-subscriprion/newsletter-subscriprion.component';
import { AuthGuard } from './authentication/services/auth.guard';
import { SocialLoginSetPasswordComponent } from './components/preAuthPages/social-login-set-password/social-login-set-password.component';
import { ChangePasswordComponent } from './components/preAuthPages/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/preAuthPages/forgot-password/forgot-password.component';
import { NgModule, isDevMode } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './components/preAuthPages/register/register.component';
import { HomeComponent } from './components/home/home.component';
import { LoggedInUserGuard } from './authentication/services/loggedInUser.guard';
import { DiscoverAppComponent } from './components/discover-app/discover-app.component';
import { DiscoverAppDetailsComponent } from './components/discover-app-details/discover-app-details.component';
import { SearchPageComponent } from './shared/components/search-page/search-page.component';
import { VerificationComponent } from './shared/components/verification/verification.component';
import { NewsletterComponent } from './components/newsletter/newsletter.component';
import { NewsletterPreviewComponent } from './components/newsletter-preview/newsletter-preview.component';
import { TechStackComponent } from './shared/components/tech-stack/tech-stack.component';

const routes: Routes = [
    // {
    //     path: '',
    //     redirectTo: 'home',
    //     // component: HomeComponent,
    //     pathMatch: 'full',
    // },
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full',
    },
    {
        path: 'login',
        loadChildren: () =>
            import('./components/preAuthPages/login/login.module').then(
                (m) => m.LoginModule
            ),
        canActivate: [LoggedInUserGuard],
    },
    {
        path: 'register',
        component: RegisterComponent,
        pathMatch: 'full',
        canActivate: [LoggedInUserGuard],
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        pathMatch: 'full',
        canActivate: [LoggedInUserGuard],
    },
    {
        path: 'reset_password',
        component: ChangePasswordComponent,
        pathMatch: 'full',
    },
    {
        path: 'set_password',
        component: SocialLoginSetPasswordComponent,
        pathMatch: 'full',
    },
    {
        path: 'profile',
        loadChildren: () =>
            import('./components/post-auth-pages/post-auth-pages.module').then(
                (m) => m.PostAuthPagesModule
            ),
        canActivate: [AuthGuard],
    },
    {
        path: 'newsletter',
        component: NewsletterSubscriprionComponent,
        pathMatch: 'full',
    },
    {
        path: 'discover-app',
        component: DiscoverAppComponent,
        pathMatch: 'full',
    },
    {
        path: 'discover-app-detail',
        component: DiscoverAppDetailsComponent,
        pathMatch: 'full',
    },
    {
        path: 'search',
        component: SearchPageComponent,
        pathMatch: 'full',
    },
    {
        path: 'newsletter-verification',
        component: VerificationComponent,
        pathMatch: 'full',
    },
    {
     path : 'newsletters',
     component : NewsletterComponent,
     pathMatch : 'full'
    },
    {
     path : 'newsletter/:id',
     component : NewsletterPreviewComponent,
     pathMatch : 'full'
    },
    {
        path: 'app',
        loadChildren: () =>
            import(
                './components/post-auth-pages/app-section/app-section.module'
            ).then((m) => m.AppSectionModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'info',
        loadChildren: () =>
            import(
                './components/informatory-pages/informatory-pages.module'
            ).then((m) => m.InformatoryPagesModule),
    },
    {
        path: 'knowledge-section',
        loadChildren: () =>
            import(
                './components/preAuthPages/knowledge-section/knowledge-section.module'
            ).then((m) => m.KnowledgeSectionModule),
    },
    {
        path : 'tech-stack',
        component : TechStackComponent,
        pathMatch : 'full'
       },
    {
        path: '**',
        component: HomeComponent,
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            useHash: false,
            scrollPositionRestoration: 'top'
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
