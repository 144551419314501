<section class="form-pages pt-120 bg-center discover-app-page pb-280">
    <ngx-spinner></ngx-spinner>
    <div class="container">
        <div class="row align-items-center main-row">
            <div class="col-md-12 form-right-content">
                <div class="mw-100">
                    <div class="static-banner relative ml-0 mr-0 mt-2">
                        <img src="assets/img/discover-app/discoverapp-banner.jpg" alt="" class="w-100">
                        <div class="over-content">
                            <h2>Discover Apps</h2>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor </p> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row cate-option">
            <div class="carousel art mb-0 carousel-inner">
                <ngx-slick class="carousel" #focusCategory="slick-modal" [config]="slideConfigArticles">
                    <a ngxSlickItem *ngFor="let cat of catList" [ngClass]="((cat._id).toString() == activeCat?.toString()) ? 'active' : ''" class="slide" href="javascript:;" [routerLink]="['/discover-app']" [queryParams]="{id: cat._id}">
                        <div class="form-group text-center cat_item" (click)="categorySelected(cat._id)">
                            <div class="cate-img">
                                <img src="{{imgUrl}}{{cat.image}}" alt="">
                                <!-- <img *ngIf="cat.name == 'Agriculture'" src="assets/img/appcategory-icon1.png">
                                <img *ngIf="cat.name == 'Health' || cat.name == 'Safety'"
                                    src="assets/img/appcategory-icon2.png">
                                <img *ngIf="cat.name == 'Education'" src="assets/img/appcategory-icon3.png">
                                <img *ngIf="cat.name == 'Governance'" src="assets/img/appcategory-icon4.png">
                                <img *ngIf="cat.name == 'Disability'" src="assets/img/appcategory-icon5.png"> -->
                            </div>
                            <h4>{{cat.name}}</h4>
                        </div>
                    </a>
                </ngx-slick>
            </div>









            <!-- <div class="col-md-cate cate-wrap" *ngFor="let cat of catList" [ngClass]="((cat._id).toString() == activeCat.toString()) ? 'active' : ''">
                <div class="form-group text-center cat_item" (click)="categorySelected(cat._id)" >
                    <div class="cate-img">
                        <img *ngIf="cat.name == 'Agriculture'" src="assets/img/appcategory-icon1.png">
                        <img *ngIf="cat.name == 'Health' || cat.name == 'Safety'"
                            src="assets/img/appcategory-icon2.png">
                        <img *ngIf="cat.name == 'Education'" src="assets/img/appcategory-icon3.png">
                        <img *ngIf="cat.name == 'Governance'" src="assets/img/appcategory-icon4.png">
                        <img *ngIf="cat.name == 'Disability'" src="assets/img/appcategory-icon5.png">
                    </div>
                    <h4>{{cat.name}}</h4>
                </div>
            </div> -->
        </div>
        <!-- cate-option end -->

        <div class="category-heading">
            <h2>All Apps</h2>
        </div>
        <div class="row category-listing ">
            <div class="col-md-3 category-sidebar">
                <div class="wrap h-100">
                    <div class="row">
                        <div class="col-lg-12">
                            <!-- #appTypeChange -->
                            <button (click)="resetOption()" title="Reset Filters" style="float:right;" class="backbtn">Reset Filters <i class="fa fa-filter text-dark"></i></button>
                        </div>
                    </div>

                    <div class="single category">
                        <h3 *ngIf="subCatList && subCatList.length" class="side-title relative">
                            <a>Sub Categories <i class="fa fa-caret-up" aria-hidden="true"></i>
                            </a>
                        </h3>

                        <div class="custom-control custom-radio" *ngFor="let sbCat of subCatList; let aIndex = index">
                            <input type="radio" [checked]="(currentSubCategory?._id) == (sbCat._id)" name="defaultExampleRadios" class="custom-control-input" id="{{aIndex}}" [value]="sbCat._id" (change)="subCatSelected($event.target.value)">
                            <label class="custom-control-label" for="{{aIndex}}">{{sbCat.name}}</label>
                        </div>
                    </div>
                    <div class="single category">
                        <h3 class="side-title relative">
                            <a>App Type <i class="fa fa-caret-up" aria-hidden="true"></i>
                            </a>
                        </h3>
                        <div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [checked]="(appInfo?.app_type) == 'Efficiency'" [value]="'Efficiency'" name="app-type" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n1">
                                <label class="custom-control-label" for="n1">Efficiency</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [checked]="(appInfo?.app_type) == 'F-Droid'" [value]="'F-Droid'" name="app-type" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n2">
                                <label class="custom-control-label" for="n2">F-Droid</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [checked]="(appInfo?.app_type) == 'iOS'" [value]="'iOS'" name="app-type" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n3">
                                <label class="custom-control-label" for="n3">IOS</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [checked]="(appInfo?.app_type) == 'Knowledge'" [value]="'Knowledge'" name="app-type" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n4">
                                <label class="custom-control-label" for="n4">Knowledge</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [checked]="(appInfo?.app_type) == 'Android'" [value]="'Android'" name="app-type" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n5">
                                <label class="custom-control-label" for="n5">Android</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [checked]="(appInfo?.app_type) == 'Learning'" [value]="'Learning'" name="app-type" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n6">
                                <label class="custom-control-label" for="n6">Learning</label>
                            </div>
                            <div class="custom-control custom-radio">
                                <input type="radio" [checked]="(appInfo?.app_type) == 'Market Place'" [value]="'Market Place'" name="app-type" (change)="appTypeSelected($event.target.value)" class="custom-control-input" id="n7">
                                <label class="custom-control-label" for="n7">Market Place</label>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <!-- category-sidebar end -->
            <div class="col-md-9 category-content">
                <div class="wrap">
                    <div class="row">
                        <div class="col-3-md">
                            <button (click)="backButton()" title="Back" class="backbtn"><i class="fa fa-long-arrow-left"
                                    aria-hidden="true"></i> Back</button>
                        </div>
                    </div>



                    <div class="row mt-0">
                        <div class="col-md-3 py-2 d-flex align-items-center"><img [src]="imgUrl+appInfo?.app_logo" class="applogo" alt="applogo"></div>
                        <div class="col">
                            <h3 class="font30 mt-3 mb-3" style="align-items: center;">{{appInfo.name}}</h3>
                            <div class="row mt-3 app-share publisher-row" *ngIf="appInfo?.publisher || appInfo?.rating">
                                <div class="col-lg-12" *ngIf="appInfo?.publisher">
                                    <h6 class="mb-2 mt-0 font-bold">App published by :</h6>
                                    <a href="{{appInfo?.publisher?.url}}" target="_blank" class="text-uppercase">{{appInfo?.publisher?.name}}</a>
                                </div>


                                <div class="col-md-12 shae-col mt-2">
                                    <p class="mb-2">Share this on</p>
                                    <ul class="share-now d-flex">
                                        <li>
                                            <a href="https://www.facebook.com/sharer.php?t={{appInfo?.name}}&u={{socialShareUrl}}" target="_blank"><img src="assets/img/case-study/share-icon3.png" alt=""></a>
                                        </li>
                                        <li>
                                            <a href="https://www.linkedin.com/sharing/share-offsite/?url={{socialShareUrl}}" target="_blank"><img src="assets/img/case-study/share-icon5.png" alt=""></a>
                                        </li>
                                        <li>
                                            <a href="https://twitter.com/intent/tweet?url={{socialShareUrl}}&text={{appInfo?.name}}" target="_blank"><img src="assets/img/case-study/share-icon6.png" alt=""></a>
                                        </li>
                                    </ul>
                                </div>

                            </div>




                        </div>
                        <div class="col-md-4">
                            <div class="row mt-3 publisher-row" *ngIf="appInfo?.publisher || appInfo?.rating">

                                <div class="col-lg-12 shae-col text-right dis-rating" *ngIf="appInfo?.rating">

                                    <h6 class="mb-0 mt-0 font-bold">Ratings :</h6>

                                    <div class="rating">
                                        <rating [(ngModel)]="appInfo.rating" [readonly]="true"></rating>
                                    </div>
                                </div>

                                <div class="playstore col-md-12  text-right" *ngIf="appInfo?.links?.android || appInfo?.links?.ios ||appInfo?.links?.fdroid ||appInfo?.links?.windows">
                                    <div class="wraps" *ngIf="appInfo?.links.android">
                                        <a href="{{appInfo.links.android}}" target="_blank"><img src="assets/img/google-play.png"></a>
                                    </div>
                                    <div class="wraps" *ngIf="appInfo?.links.ios">
                                        <a href="{{appInfo?.links?.ios}}" target="_blank"><img src="assets/img/app2.png" alt=""></a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div class="app-screens" id="htmlcontent">
                        <div class="center slider">
                            <ngx-slick class="carousel" #slickModal="slick-modal" [config]="slideConfig">
                                <div ngxSlickItem *ngFor="let slide of screenshots" class="slide">
                                    <img [src]="slide ? imgUrl+slide : dummy" alt="" width="100%">
                                </div>
                            </ngx-slick>
                        </div>
                    </div>
                  
                    <div style="align-items: center; display: flex; justify-content: center;">
                        <div *ngIf="issafeSrc">
                            <iframe [src]="safeSrc" width="200px" height="200px" allowfullscreen></iframe>
                        </div>   
                    </div>
                   
                    <div>
                        <p  bind-innerHTML="transform(appInfo.app_summary)">
                        </p>
                    </div>

                    <!-- <div class="signleapp-features">
                        <div class="row mt-0">
                            <div class="col-md-12 text-center">
                                <div class="rating-div"><span><strong>Rating:</strong></span> <img src="assets/img/app-ratings.png" class="ml-2"></div>
                                <div class="tags mb-4 mt-4"><a href="" class="btn btn-default blue-btn rounded-pill text-white" href="#">#Education</a>
                                    <a href="" class="btn btn-default orange-btn rounded-pill text-white ml-2 mr-2" href="#">#Literacy</a>
                                    <a href="" class="btn btn-default red-btn rounded-pill text-white" href="#">#Classroom</a></div>
                                <p class="text-grey">The app provides a simple management platform that can be used for easy administration of school, college or coaching classes. Users can manage the attendance of students, share marks, send fees reminder etc through the app.</p>
                            </div>
                        </div>
                    </div> -->
                    <div class="key-features">

                        <div class="w-100 bg-light card p-4">
                            <div class="row mt-0" *ngIf="appInfo?.features && appInfo?.features?.length">
                                <div class="col-md-12 text-center">
                                    <h2>Key Features</h2>
                                </div>
                            </div>
                            <div class="row key-row mt-0" *ngIf="appInfo?.features && appInfo?.features?.length">
                                <div class="col-md-4 kry-col  mt-5 mt-md-0 text-center" *ngFor="let feature of appInfo?.features">
                                    <div class="wrap">
                                        <img src="assets/img/keyfeat-icon.png">
                                        <h3 class="mb-2 mt-3">{{feature?.title}}</h3>
                                        <p class="mb-0" [innerHTML]="transform(feature?.description)"></p>
                                    </div>
                                </div>
                                <!-- kry-col end -->
                                <!-- <div class="col-md-4 mt-5 mt-md-0 kry-col text-center">
                                <div class="wrap">
                                    <img src="assets/img/discover-app/key-icon2.png">
                                    <h3 class="mb-2 mt-3">Marks</h3>
                                    <p class="mb-0">The app shares marks of a student with their parents along with
                                        highest and lowest marks of the class.</p>
                                </div>
                            </div>
                            <div class="col-md-4 kry-col  mt-5 mt-md-0 text-center">
                                <div class="wrap">
                                    <img src="assets/img/discover-app/key-icon3.png">
                                    <h3 class="mb-2 mt-3">Reminders</h3>
                                    <p class="mb-0">Monthly fee reminders can be sent.</p>
                                </div>
                            </div> -->
                            </div>
                            <!-- key-row end -->
                        </div>
                        <div class="row mt-3 " *ngIf="appInfo?.tags && appInfo?.tags.length">
                            <div class="col-md-12 shae-col">
                                <h2 class="mb-2">Tags</h2>
                                <div class="mt-0 mr-0 w-100 d-flex align-items-center flex-wrap">
                                    <div class="tag-col mb-0 mr-2" *ngFor="let item  of appInfo?.tags">
                                        <a (click)="tagClickRedirection(item)">
                                            <span class="mb-0 btn btn-default blue-btn rounded-pill text-white">#{{ item }}</span></a>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="rating-row row mt-3" *ngIf="appInfo?.publisher">
                            <div class="col-md-6 shae-col">
                                <h2 class="mb-2 mt-0">App published by:</h2>
                            </div>
                            <div class="col-md-6 shae-col">
                                <a href="{{appInfo?.publisher?.url}}" target="_blank">{{appInfo?.publisher?.name}}</a>
                            </div>
                        </div>
                        <div class="rating-row row mt-3" *ngIf="appInfo?.rating">
                            <div class="col-md-6 shae-col">
                                <h2 class="mb-2 mt-0">Ratings</h2>
                            </div>
                            <div class="rating">
                                <rating [(ngModel)]="appInfo.rating" [readonly]="true"></rating>
                            </div>
                        </div> -->




                    </div>
                </div>
            </div>
            <!-- category-content -->
        </div>

        <div class="similar-app-slider row" *ngIf="appList && appList.length">
            <div class="col-md-12">
                <h3>Similar apps</h3>
                <div class="carousel art mb-0 carousel-inner">
                    <ngx-slick class="carousel" #slickModal="slick-modal" [config]="slideConfigSimilarApps">
                        <div ngxSlickItem *ngFor="let app of appList" class="slide" (click)="detailOfApp(app)">
                            <div class="category-listing-col text-center">
                                <div class="wrap h-100">
                                    <div class="app-logo">
                                        <img [src]="app.app_logo ? imgUrl+app.app_logo : dummyImg" alt="">
                                    </div>
                                    <h3 class="font-bold mt-3 mb-3">{{app.name}}
                                    </h3>
                                    <div class="rating">
                                        <rating [(ngModel)]="app.rating" [readonly]="true"></rating>
                                    </div>
                                    <!-- <div class="rating mb-3"><img src="assets/img/app-ratings.png"></div> -->
                                    <h4 *ngIf="app.publisher"> <b>By {{app.publisher.name}}</b></h4>
                                    <!-- <p [innerHTML]="app.app_summary"></p> -->
                                </div>
                            </div>
                        </div>
                    </ngx-slick>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Hero -->

<!-- <carousel [itemsPerSlide]="itemsPerSlide" [singleSlideOffset]="singleSlideOffset"
[isAnimated]="true" [interval]="cycleInterval" [noWrap]="noWrap">
<slide *ngFor="let app of appList">
     <div class="category-listing-col text-center">
        <div class="wrap h-100">
    <div class="app-logo">
        <img [src]="app.app_logo ? imgUrl+app.app_logo : dummyImg" alt="">
    </div>
    <h3 class="font22 font-bold mt-3 mb-3">{{app.name}}
    </h3>
    <div class="rating">
        <rating [(ngModel)]="app.rating" [readonly]="true"></rating>
    </div>
    <h4 *ngIf="app.publisher"> <b>By {{app.publisher.name}}</b></h4>
</div>
</div>
</slide>
</carousel> -->