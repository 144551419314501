 <!-- ======= Footer ======= -->
  <footer id="footer" class="hide-on-init" animateOnScroll animationName="animated fadeIn">
    <div class="subscribe-form" *ngIf="showNewsletterBox"> 
      <h2>Want More? Get our Insights and <br>
    stories right in your inbox
    </h2>
      <form action="" [formGroup]="subscriptionForm">
        <input type="email" name="email" formControlName="email" class="form-control rounded-pill" placeholder="Email Address" required />
        <button type="submit" class="btn rounded-pill btn-default" (click)="signUpForUs(template)">Sign me up</button><br />
        <ng-container *ngIf="subscriptionForm.controls.email.errors && subscriptionFormSubmitted">
          <small
            [hidden]="!subscriptionForm.controls.email.errors.required">*Email is
            required.</small>
          <small
            [hidden]="!subscriptionForm.controls.email.errors.pattern">
            *Enter Valid E-mail.
          </small>
        </ng-container>
      </form>
    </div>

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-md-4 footer-contact">
         <img src="assets/img/footer-logo.png" alt="">
         <div class="col-md-12 ref-logos pl-0 pr-0 mt-2">
          
          <a href="https://www.myvi.in/about-us/vodafoneidea-foundation" target="_blank" >
             <p>DESIGNED & EXECUTED BY</p>
            <img src="assets/img/vodafone1.png" class="ref1-logo"></a>
         
          <a href="https://nasscomfoundation.org/" target="_blank" >
 <p>PRINCIPAL PARTNER</p>
            <img src="assets/img/nasscom1.png" class="ref2-logo"></a>
        </div>
          </div>

          <div class="col-md-4 footer-links mt-4 mt-md-0">
            <h4>Discover Apps</h4>
            <ul>
              <li *ngFor="let cat of catList">
                <a [routerLink]="['/discover-app']" [queryParams]="{id: cat?.id}" >{{cat.name}}</a>
              </li>
            </ul>
          </div>


          <div class="col-md-4 app-store">
            <h6>Download SOCIAL APP HUB</h6>
            <div class="app-store-div d-flex">
              <a href="https://play.google.com/store/apps/details?id=com.vfsocialhub.app" target="_blank"><img src="assets/img/app1.png" alt=""></a>
              <a href="https://apps.apple.com/in/app/socialapphub-vodafone-foundation/id1258678376" target="_blank"><img src="assets/img/app2.png" alt=""></a>
            </div>
            <div class="social-icon">
              <h4>Follow Us</h4>
              <ul>
                <li><a href="https://www.facebook.com/socialapphub" target="_blank"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                <li><a href="https://twitter.com/SocialAppHub" target="_blank"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                <li><a href="https://in.linkedin.com/" target="_blank"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                <li><a href="https://instagram.com/socialapphub?igshid=9foj2xxzn44z" target="_blank"><i class="fa fa-instagram" aria-hidden="true"></i></a></li>
              </ul>
            </div>
          </div>




        </div>
      </div>
    </div>

    <div class="container py-4 copyright-bottom">
  <div class="footer-links-bottom">
            <!-- <h4>Categories</h4> -->
            <ul>
              <li *ngFor="let page of staticpages" class="pt-0"><a [routerLink]="['/info/page/', page.type]">{{ page.title }}</a></li>
              <!-- <li><a routerLink="/info/privacy-policy">Privacy Policy</a></li>
              <li class="pt-0"><a routerLink="/info/terms-and-conditions">Terms and Conditions</a></li>
              <li class="pt-0"><a routerLink="/info/our-story">Our Story</a></li>
              <li class="pt-0"><a routerLink="/info/disclaimer">Disclaimer</a></li> -->
            </ul>
          </div>
      <div class="mr-md-auto text-center">
        <div class="copyright">
          <!-- Copyright Vodafone Idea CSR © 2020. All Rights Reserved -->
          © 2020 Vodafone Idea CSR
        </div>
      </div>
    </div>
  </footer><!-- End Footer -->

  <ng-template #template>
  <div class="modal-body text-center">
    <h4>{{tempSubscriptionMessage}}</h4>
</div>
<div class="modal-footer text-center">
    <button type="button" class="btn btn-default" (click)="closePopUp()">Close</button>
</div>
</ng-template>
