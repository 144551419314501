<section class="feature-banner">
    <div class="container">
        <div class="row bg-white TechStack p-lg-5 py-5 shadod-2 mb-lg-5">
            <div class="col-lg-auto text-center mr-lg-4">
                <div class="ProfileArea text-center">
                    <div class="ProfilePic mb-4 mx-auto">
                        <img src="/assets/img/Tech_image.png" />
                    </div>
                    <h4 class="mb-3">Gagandeep</h4>
                    <p>Head of Technology at <br><span>Vi Foundation</span></p>
                </div>
            </div>
            <div class="col">
                <div class="ProfileRightArea mt-lg-0 mt-4">
                    <h4>About Me</h4>
                    <p><strong>Gagandeep</strong> being the Head of Technology
                        at <strong> Vi Foundation,</strong> led the
                        Product Engineering, IT, Security and Technology
                        Operations
                        teams/partners. His primary focus areas include driving
                        the organization’s
                        transformation into product lines, advancing digital
                        transformation and
                        integrating cutting-edge technologies such as Artificial
                        Intelligence and
                        Cloud services. </p>

                    <p>Gagandeep is also passionate about the development of
                        social sector projects, striving to make them the
                        preferred choice for both corporates and Not for Profit
                        organizations </p>

                    <p>One of the key initiatives under his leadership was the
                        creation of <strong> SOCIALAPPHUB, India's
                            first</strong> crowdsourcing platform aimed at
                        curating, strengthening & promoting social sector
                        related mobile solutions from critical sectors of
                        health, education, governance, agriculture, disability
                        and safety. The platform also offers a plethora of
                        knowledge through its case studies, articles &
                        interviews to foster technology enabled social
                        innovation in the development sector. Learn more about
                        <strong> SOCIALAPPHUB </strong>
                        <a style="color: #e03a3c;"
                            href=" https://www.socialapphub.com/">
                            https://www.socialapphub.com/ </a> </p>

                    <p>With a long history of enabling technology solutions,
                        Gagandeep previously served as the Head of Technology at
                        the <strong>

                            NASSCOM Foundation, </strong> where he spearheaded
                        numerous projects working with Cisco, Microsoft, SAP,
                        Ciena and many other corporate to empower grassroots
                        communities in scaling their operations and make a
                        lasting impact.</p>

                    <p>Gagan is always open to exchanging ideas and assisting
                        non-profits in optimizing their operations, ensuring
                        maximum impact with minimal effort.</p>
                </div>

            </div>
        </div>
    </div>
</section>
