import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './pipes/strip-html.pipe';
// import { CarouselModule } from 'ngx-bootstrap/carousel';
import { HoverClassDirective } from './derectives/hover.directive';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { ModalModule} from 'ngx-bootstrap/modal';
import { TechStackComponent } from './components/tech-stack/tech-stack.component';

@NgModule({
  declarations: [SafeHtmlPipe,HoverClassDirective, TechStackComponent],
  imports: [
    CommonModule,
    // CarouselModule.forRoot(),
    AnimateOnScrollModule.forRoot(),
    ModalModule.forRoot(),
  ],
  exports:[SafeHtmlPipe,HoverClassDirective, AnimateOnScrollModule]
})
export class SharedModule { }
